
.loading-text {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 60px;
    position: fixed;
    margin-top: 250px;
    font-size: 30px;
    color: #54575a;
    z-index: 99999999; /* Ensure it's above other content */
  }
  
  .loading-text::after {
    content: ".";
    animation: ellipsis 1.25s infinite steps(3);
  }
  
  @keyframes ellipsis {
    0% { content: "."; }
    20% { content: ".."; }
    40% { content: "..."; }
    60% { content: "...."; }
    80% { content: "....."; }
    100% { content: "......"; }
  }
  
  
  .logo-container {
    position: absolute;
  }
  
  .mobile-logo {
    max-width: 100px; /* Adjust the size as needed */
    height: auto; /* Maintain the aspect ratio */
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999; /* Ensure it's above other content */
  }
  
  .spinner {
    /* Your spinner styling here */
    /* Example: */
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #2dccd3;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: spin 1s linear infinite;
    z-index: 9999999; /* Ensure it's above other content */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  