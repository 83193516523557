.bg-video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out; /* Adjust the duration and easing function as needed */
  }
  
  .required {
    color: red;
  }

  .shadow-border {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); /* Adjust the opacity to make it darker */
  }
  
  .form-error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.25rem;
  }
  